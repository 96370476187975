#trendingThisMonthSectionContainer {
    background: none;
}

@media(min-width: 600px) {
    #trendingThisMonthSectionContainer {
        margin-bottom: 1.5em;
    }
}

@media(min-width: 1000px) {
    #trendingThisMonthSectionContainer {
        margin-bottom: 3em;
    }
}
